import React from 'react';

const styles = {
  card: {
    backgroundColor: '#FFFFFF',
    textAlign: 'left',
    width: 400,
    padding: 48,
    marginTop: 72
  },
  title: {
    fontWeight: 500,
    fontSize: 24,

  },
  body: {
    fontWeight: 'normal',
    fontSize: 20,
  }
}

function OurProductCard({title, body}) {
  return (
      <div style={styles.card}>
        <p style={styles.title}>{title}</p>
        <p style={styles.body}>{body}</p>
      </div>
  );
}

export default OurProductCard;