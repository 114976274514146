import React from 'react';
import styled from "styled-components";

const styles = {
  card: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginTop: 40
  }
}

const Body = styled.p`
    font-weight: normal;
    font-size: 20px;
    width: 50%;
    margin: auto;
  @media (max-width: 768px) {
    font-size: 16px;
  }`

function GoToMarketCard({width, height, size, backgroundColor, squareBackgroundColor, color, body}) {
  return (
      <div style={{...styles.card, backgroundColor: backgroundColor, maxWidth: width, height}}>
        <div style={{width: size, height: size, backgroundColor: squareBackgroundColor, margin: 'auto'}}>

        </div>
        <Body style={{color: color}}>{body}</Body>
      </div>
  );
}

export default GoToMarketCard;