import React from 'react';
import Card from './go-to-market-card'

const styles = {
  section: {
    backgroundColor: '#F2F2F2',
    paddingTop: 100,
    paddingBottom: 133,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: {
    maxWidth: 1200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center'
  },
  title: {
    fontWeight: 500,
    fontSize: 40,
    marginBottom: 42
  },
  topic: {
    fontWeight: 'normal',
    fontSize: 20,
    marginTop: 0,
    marginBottom: 0
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: 1200
  },
  button: {
    paddingBottom: 12,
    paddingTop: 12,
    width: 232,
    marginTop: 95,
    borderColor: 'unset',
    fontWeight: 'bold',
    fontSize: 18,
    backgroundColor: '#CC922F',
    color: '#FFFFFF',
  }
}

function GoToMarket() {
  return (
      <div id={'go-to-market'} style={styles.section}>
        <Card height={370} width={1200} size={160} backgroundColor={'#000000'} squareBackgroundColor={'#FFFFFF'} color={'#FFFFFF'}
              body={'BrandScout is not a replacement for trade shows, huge B2B platforms like Alibaba nor Sales Headcount, but the perfect customized add-on to save costs, free for buyers! '}/>
        <div style={styles.cardContainer}>
          <Card style={{marginTop: 72}} height={330} width={570} size={116} backgroundColor={'#FFFFFF'} squareBackgroundColor={'#C4C4C4'} color={'#000000'}
                body={'Our 1st focus market is EU, where a relatively small group of buyers take decisions over a high amount of POS, representing an estimated market of 20-80 Million for a mid-sized CE brand. BrandScout has already received interest of influential buyers that represent 5000+ POS.'}/>
          <Card height={330} width={570} size={116} backgroundColor={'#FFFFFF'} squareBackgroundColor={'#C4C4C4'} color={'#000000'}
                body={'Offering free trials to bring in Brands during the first year, we want to leverage our network to become the 1 B2B purchasing app in Europe, and activate our paid subscription model after the first year.'}/>
        </div>
        <button style={styles.button}>LEARN MORE</button>
      </div>
  );
}

export default GoToMarket;