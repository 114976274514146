import React from 'react';
import styled from "styled-components";

const styles = {
  section: {
    color: '#FFFFFF',
    backgroundColor: '#353536',
    backgroundImage: 'url("/assets/media/img/screens/group/Group2.png")',
    backgroundPosition: 'right top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '50% 50%',
    paddingLeft: '5%',
    paddingRight: '5%',
    paddingTop: 171,
    paddingBottom: 183
  },
  title: {
    paddingLeft: '5%',
    paddingRight: '5%',
    fontWeight: 500,
    fontSize: 40
  },
  body: {
    paddingLeft: '5%',
    paddingRight: '5%',
    fontWeight: 'normal',
    fontSize: 20,
    maxWidth: 422
  },
  image: {
    width: '50%'
  }
}

const Container = styled.div`
    padding-top: 400;
    display: flex;
    flex-direction: row;
    justify-content: space-even;
  @media (max-width: 768px) {
    flex-direction: column;
  }`

function OurSolution() {
  return (
      <div id={'our-solution'} style={styles.section}>
        <p style={styles.title}>OUR SOLUTION</p>
        <p style={styles.body}>
          For brands it eliminates the immense barrier of entry (lack of network and feedback), and for buyers it eliminates the high amount of clutter and flood of meeting requests, as well as helping them to discover new products efficiently.
        </p>
        <p style={styles.body}>
          The like / dislike feature for their sales pitch, offers brands guaranteed feedback and thus sales, leads or at least valuable market intelligence segmented by country and channel type.
        </p>
        <Container>
          <img style={styles.image}/>
          <p style={{...styles.body, width: '43%'}}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra ultrices sed nec tincidunt nunc nisl risus magna egestas. Integer in morbi odio lorem lobortis vitae et dictum eu. Sit quis integer faucibus suspendisse et sed tempor. Sagittis turpis aenean aenean varius facilisis condimentum aliquet faucibus bibendum. Adipiscing enim duis fusce semper elementum vestibulum cursus libero. Tincidunt sed massa risus at vestibulum in. Arcu vitae dictum sed vitae.
          </p>
        </Container>
      </div>
  );
}

export default OurSolution;