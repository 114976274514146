import React from 'react';
import styled from 'styled-components'

const styles = {
  section: {
    color: '#FFFFFF',
    backgroundColor: '#1B1B1A',
    paddingTop: 110,
    paddingBottom: 35,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  separator:{
    marginTop: 48,
    width: '69.44%',
    background: '#FFFFFF',
    border: '1px solid #000000'
  },
  circleContainer: {
    paddingTop: 55,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 296
  },
  circle: {
    borderRadius: '50%',
    backgroundColor: '#FFFFFF',
    width: 40,
    height: 40
  },
  policy: {
    marginTop: 72,
    fontSize: 14,
    fontWeight: 'normal'
  }
}

const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
    justify-content: space-between;
    max-width: 800px;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-end;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }`
const Link = styled.a`
  text-decoration: none;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
  @media (max-width: 768px) {
    padding: 15px;
  }`
const LinkTop = styled.a`
  text-decoration: none;
    color: #FFFFFF;
    font-size: 24px;
    font-weight: bold;
  @media (max-width: 768px) {
    padding: 15px;
  }`

function Footer() {
  return (
      <div style={styles.section}>
        <LinkContainer>
          <Link href={'#our-product'}>Our Product</Link>
          <Link href={'#problems-and-oportunity'}>Problems & Opportunities</Link>
          <LinkTop href={'#'}>BRANDSCOUT</LinkTop>
          <Link href={'#our-solution'}>Our Solution</Link>
          <Link href={'#go-to-market'}>Go to Market</Link>
        </LinkContainer>
        <div style={styles.separator}/>
        <div style={styles.circleContainer}>
          <div style={styles.circle}/>
          <div style={styles.circle}/>
          <div style={styles.circle}/>
          <div style={styles.circle}/>
          <div style={styles.circle}/>
        </div>
        <p style={styles.policy}>© 2010 — 2020 Privacy — Terms</p>
      </div>
  );
}

export default Footer;