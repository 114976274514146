import React from 'react'
import { connect } from "react-redux"
import { logoutUser } from "../screens/auth/actions";

import styled from 'styled-components'


const Button = styled.button`
    background-color: #FFFFFF;
    color: #353536;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 25px;
    border-radius: 5px;
    padding-right: 25px;
    margin-right: 6.9%;
    height: 32px;
    font-weight: 500;
    font-size: 18px;`
function LogOut({dispatch}) {
  let handleClick = () => {
    dispatch(logoutUser())
  }
  return <Button onClick={handleClick}>Log Out</Button>
}

export default connect(null)(LogOut);