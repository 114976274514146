import React from 'react';
import styled from "styled-components";

const styles = {
  section: {
    color: '#FFFFFF',
    backgroundColor: '#353536',
    backgroundImage: 'url("/assets/media/img/screens/group/Group.png")',
    backgroundPosition: '-65% 50%',
    paddingLeft: '10%',
    paddingTop: 180,
    paddingBottom: 133
  },
  logo: {
    width: 190
  },
  topic: {
    fontWeight: 500,
    fontSize: 20,
    maxWidth: 422
  },
  button: {
    paddingBottom: 12,
    paddingTop: 12,
    width: 232,
    borderColor: 'unset',
    fontWeight: 'bold',
    fontSize: 18,
    backgroundColor: '#CC922F',
    color: '#FFFFFF',
  }
}

const Title = styled.p`
    font-weight: 500;
    font-size: 56px;
  @media (max-width: 768px) {
    font-size: 38px;
  }`

function Presentation() {
  return (
      <div style={styles.section}>
        <img style={styles.logo} src={'/assets/media/img/fox.png'}/>
        <Title>BRANDSCOUT</Title>
        <p style={styles.topic}>THE FIRST ONLINE TRADE SHOW FOR CONSUMER ELECTRONICS</p>
        <button style={styles.button}>LEARN MORE</button>
      </div>
  );
}

export default Presentation;