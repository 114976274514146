import React from 'react';
import styled from "styled-components";

const styles = {
  section: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flexWrap: 'wrap'
  },
  title: {
    fontWeight: 500,
    fontSize: 40,
    marginTop: 180,
    marginBottom: 57
  },
  body: {
    fontWeight: 'normal',
    fontSize: 20,
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    width: '50%'
},
  button: {
    paddingBottom: 12,
    paddingTop: 12,
    width: 232,
    borderColor: 'unset',
    fontWeight: 'bold',
    fontSize: 18,
    backgroundColor: '#CC922F',
    color: '#FFFFFF',
  }
}

const Title = styled.p`
    font-weight: 500;
    font-size: 40px;
    margin-top: 180px;
    margin-bottom: 57px;
  @media (max-width: 768px) {
    font-size: 21px;
  }`
const Topic = styled.p`
    font-weight: normal;
    font-size: 24px;
    margin-top: 0px;
  @media (max-width: 768px) {
    font-size: 21px;
  }`
const Container = styled.div`
    text-align: left;
    width: 80%;
  @media (max-width: 768px) {
    width: 100%;
  }`
const Button = styled.button`
    padding-bottom: 12px;
    padding-top: 12px;
    width: 232px;
    border-color: unset;
    font-weight: bold;
    font-size: 18px;
    background-color: #CC922F;
    color: #FFFFFF;
  @media (max-width: 768px) {
    width: 100px;
  }`

function ProblemsAndOportunity() {
  return (
      <div id={'problems-and-oportunity'} style={styles.section}>
        <img src={'/assets/media/img/screens/single/iphone-8-2.png'} style={styles.card}/>
        <div style={{...styles.card, background: '#F2F2F2'}}>
          <Container >
          <Title>Problems & Opportunities</Title>
          <Topic>For Buyers:</Topic>
          <p style={styles.body}>
            Trying daily to find matching products for their stores or projects, but receiving a flood of unfiltered & un-customized meeting requests, emails etc. Need to spend money to trade shows without enough time to visit all booths. Want to see new products but not waste time with extensive sales meetings.
          </p>
          </Container>
        </div>
        <div style={{...styles.card, background: '#F2F2F2'}}>
          <Container style={{paddingTop: 165}}>
          <p style={styles.topic}>For Brands:</p>
          <p style={styles.body}>
            Trying daily to get in touch with the right buyers, spend vast amounts of money on business development teams and trade shows.
          </p>
          <Button style={{ marginTop: 80}}>LEARN MORE</Button>
          </Container>
        </div>
        <img src={'/assets/media/img/screens/single/iphone-8-14.png'}  style={styles.card}/>
      </div>
  );
}

export default ProblemsAndOportunity;