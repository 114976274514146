import React from 'react';
import Presentation from './presentation'
import OurProduct from './our-product'
import ProblemsAndOportunity from './problems-and-oportunity'
import OurSolution from './our-solution'
import GoToMarket from './go-to-market'
import ContactUs from './contact-us'
import Header from '../../components/header'
import Footer from '../../components/footer'

function App() {
  return (
    <div className="App">
      <Header/>
      <Presentation/>
      <OurProduct/>
      <ProblemsAndOportunity/>
      <OurSolution/>
      <GoToMarket/>
      <ContactUs/>
      <Footer/>
    </div>
  );
}

export default App;
