import React, {useState} from 'react';

const styles = {
  section: {
    paddingTop: 150,
    paddingBottom: 185,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    fontWeight: 500,
    fontSize: 40,
    marginTop: 0
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    width: 300,
    height: 48,
    fontWeight: 'normal',
    fontSize: 14,
    paddingLeft: 24
  },
  button: {
    paddingBottom: 12,
    paddingTop: 12,
    marginTop: 20,
    width: 328,
    borderColor: 'unset',
    fontWeight: 'bold',
    fontSize: 18,
    backgroundColor: '#CC922F',
    color: '#FFFFFF',
    height: 48
  }
}

function ContactUs() {

  const [message, setMessage] = useState('')
  const handleChange = e => {
    setMessage( e.target.value)
  }
  const handleSubmit = () => {
  }
  return (
      <div style={styles.section}>
        <p style={{...styles.title, marginBottom: 0}}>Want to know more about Brandscout?</p>
        <p style={{...styles.title, marginBottom: 100}}>Contact us!</p>
        <form style={styles.form} onSubmit={handleSubmit}>
          <input placeholder={'Email'} style={styles.input}/>
          <textarea value={message} onChange={handleChange} name="message" placeholder={'Message'} style={{...styles.input, marginTop: 20, paddingTop: 24}} rows="10"/>
            <input style={styles.button} type="submit"/>
        </form>
      </div>
  );
}

export default ContactUs;