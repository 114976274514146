import React, {useState, useEffect} from 'react'
import { connect } from "react-redux"
import styled from 'styled-components'
import LogIn from './log-in'
import LogOut from './log-out'

const styles = {
  section: {
    color: '#FFFFFF',
    backgroundColor: '#1B1B1A',
    height: 80,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    width: '100%'
  },
  brandScout: {
    color: '#FFFFFF',
    paddingLeft: '4.7%',
    fontSize: 24,
    fontWeight: 'bold',
    textDecoration: 'none'
  },
  menuContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '45.69%'
  }
}

const Link = styled.a`
  text-decoration: none;
    color: #FFFFFF;
    font-weight: normal;
    font-size: 16px;
  @media (max-width: 768px) {
    padding: 15px;
  }`

function Header({isAuthenticated}) {

  const [menu, setMenu] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", resize.bind());
    resize();
  });
  const resize = () => {
    setMenu( window.innerWidth >= 768);
  }

  return (
      <div style={styles.section}>
        <a style={styles.brandScout} href={'/'}>BRANDSCOUT</a>
        <div style={{...styles.menuContainer, display: menu ? 'flex': 'none'}}>
          <Link href={'#our-product'}>Our Product</Link>
          <Link href={'#problems-and-oportunity'}>Problems & Opportunity</Link>
          <Link href={'#our-solution'}>Our Solution</Link>
          <Link href={'#go-to-market'}>Go to Market</Link>
        </div>
        {isAuthenticated ? <LogOut/> : <LogIn/>}
      </div>
  );
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated
  };
}

export default connect(mapStateToProps)(Header);