import React from 'react';
import Card from './our-product-card'
import styled from "styled-components";
const styles = {
  section: {
    backgroundColor: '#F2F2F2',
    paddingTop: 100,
    paddingBottom: 133,
    display: 'flex',
    justifyContent: 'center'
  },
  container: {
    width: '100%',
    maxWidth: 1200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center'
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap'
  }
}

const Title = styled.p`
    font-weight: 500;
    font-size: 40px;
    margin-bottom: 42px;
  @media (max-width: 768px) {
    font-size: 30px;
  }`
const Topic = styled.p`
    font-weight: normal;
    font-size: 20px;
    margin-bottom: 0px;
    margin-top: 0px;
  @media (max-width: 768px) {
    font-size: 14px;
  }`

function OurProduct() {
  return (
      <div id={'our-product'} style={styles.section}>
        <div style={styles.container}>
          <Title>Our Product</Title>
          <Topic>
            The first online trade show for consumer electronics, 365 days a year, and empowered by intelligent algorithms offering 2 features for buyers
          </Topic>
          <div style={styles.cardContainer}>
            <Card title={'Elevator Pitch Sequence'} body={'Filter according to your preference and swipe (like/dislike) through a sequence of entertaining 30 seconds elevator pitches and matching brand presentations.'}/>
            <Card title={'OEM Project Requests:'} body={'Post a specific project request via APP in 5 seconds, or join public requests to achieve MOQs.'}/>
          </div>
        </div>
      </div>
  );
}

export default OurProduct;