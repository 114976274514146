import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBnSYUXtiWmdQqLr0B9jF4TDXY9WItLPxs",
  authDomain: "brandscout-staging.firebaseapp.com",
  databaseURL: "https://brandscout-staging.firebaseio.com",
  projectId: "brandscout-staging",
  storageBucket: "brandscout-staging.appspot.com",
  messagingSenderId: "657091429063",
  appId: "1:657091429063:web:490c99dfff8c5c22e5f4e2",
  measurementId: "G-DSBBMQK8GN"
};

export const myFirebase = firebase.initializeApp(firebaseConfig);
export const db = myFirebase.database();